export const YEAR = { title: 'year', value: 12 };
export const QUARTER = { title: 'quarter', value: 3 };
export const MONTH = { title: 'month', value: 1 };
export const PERIODS = [YEAR, QUARTER, MONTH];
export const POST_OPTIONS = {
	method: 'POST',
	mode: 'cors',
	headers: {
		'Content-Type': 'application/json',
		Accept: 'application/json',
	},
};