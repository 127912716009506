import React from 'react';
import { Provider, observer } from 'mobx-react';
import { BillingModal } from './BillingModal/BillingModal';
import { BillingModalConfirms } from './BillingModalConfirms/BillingModalConfirms';
import { BillingStore } from './store';

@observer
export class Billing extends React.Component {
	store = null;

	constructor(props) {
		super(props);
		this.store = new BillingStore(props);
	}

	render() {
		return (
			<Provider context={this.store}>
				<BillingModal />
				<BillingModalConfirms />
			</Provider>
		);
	}
}
