import React from 'react';
import appStore from '@appStore';
import getTabs from './tabs';
import { RouteTabs } from '@smartplatform/consta/ui/RouteTabs';

export class SettingTabs extends React.Component {
	constructor(props) {
		super(props);
		this.tabs = getTabs(appStore);
	}

	render() {
		return <RouteTabs items={this.tabs} fitMode='scroll' />;
	}
}
