import React, { createRef } from 'react';
import store from 'client/store';
import { Text } from '@consta/uikit/Text';
import { Desktop, NotPhone } from 'components';
import { observer } from 'mobx-react';
import { BackButton } from '@features/BackButton';
import { BillingButton } from '@features/BillingButton';
import { GlobarSearch } from './GlobalSearch';
import { OnlineUsers } from './OnlineUsers';
import { UserWithContextMenu } from './UserWithContextMenu';
import { Billing } from '@widgets/Billing';

import './Header.scss';

@observer
export class Header extends React.Component {
	ref = createRef(null);

	constructor(props) {
		super(props);
	}

	goToBack = () => (typeof store.ui.backRoute === 'function' ? store.ui.backRoute() : store.route.push(store.ui.backRoute));

	render() {
		return (
			<header className='Header'>
				<Desktop>
					<div className='Header-col Header-col-back-history'>
						{store.ui.backRoute && <BackButton onClick={this.goToBack} view='ghost' />}
						<Text size='m'>{store.ui.title || ''}</Text>
					</div>
				</Desktop>
				<NotPhone>
					<GlobarSearch className='Header-col' />
				</NotPhone>
				<div className='Header-col Header-col-end'>
					<Desktop>
						<OnlineUsers />
					</Desktop>
					<BillingButton inputRef={this.ref} />
					{typeof store.ui.showPopupBilling === 'boolean' && (<Billing anchorRef={this.ref} />)}
					<UserWithContextMenu />
				</div>
			</header>
		);
	}
}
