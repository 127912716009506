import Root from './Root';
import t from 'i18n';
import {
	STOREHOUSE_WAREHOUSES_PATH,
	STOREHOUSE_NOMENCLATURES_PATH,
	STOREHOUSE_NOMENCLATUREMOVEMENTS_PATH,
	STOREHOUSE_SETTINGS_PATH,
	STOREHOUSE_REPORTS_PATH,
} from '@pages/constants';

import NomenclaturesIcon from '@phosphor-icons/core/regular/package.svg';
import NomenclatureMovementsIcon from '@phosphor-icons/core/regular/box-arrow-down.svg';
import WarehouseIcon from '@phosphor-icons/core/regular/garage.svg';
import ReportsIcon from '@phosphor-icons/core/regular/cards-three.svg';
import SettingsIcon from '@phosphor-icons/core/regular/sliders-horizontal.svg';

/**
 * Конфигурация модуля.
 */

export default {
	/** Заголовок модуля */
	title: t('storehouse.title'),

	/** Короткий заголовок модуля */
	titleShort: t('storehouse.title'),

	/** Код модуля на латинице, обязателен */
	code: 'storehouse',

	/** Опциональная дополнительная функция проверки доступности модуля */
	enabled: (store) => store.model.Nomenclature && store.model.Nomenclature.INFO.WRITE,

	/** Главный компонент с роутами и компонентами модуля */
	component: Root,

	/** Необязательный компонент для общего дашбоарда */
	// dashboardCard: StorehouseCard,

	/** Меню модуля, может быть массивом или функцией (в аргументе передастся глобальный стор) */
	menu: () => [
		{
			type: 'item',
			path: STOREHOUSE_NOMENCLATURES_PATH,
			label: t('nomenclature.plural'),
			icon: () => <NomenclaturesIcon size='m' />,
		},
		{
			type: 'item',
			path: STOREHOUSE_NOMENCLATUREMOVEMENTS_PATH,
			label: t('nomenclatureMovement.title'),
			icon: () => <NomenclatureMovementsIcon size='m' />,
		},
		{
			type: 'item',
			path: STOREHOUSE_WAREHOUSES_PATH,
			label: t('productionOrder.warehouses'),
			icon: () => <WarehouseIcon size='m' />,
		},
		{
			type: 'item',
			label: t('reports.plural'),
			path: STOREHOUSE_REPORTS_PATH,
			icon: () => <ReportsIcon size='m' />,
		},
		{
			type: 'item',
			path: STOREHOUSE_SETTINGS_PATH,
			label: t('settings'),
			icon: () => <SettingsIcon size='m' />,
			enabled: (store) => store.model.Nomenclature && store.model.Nomenclature.INFO.WRITE && store.model.NomenclatureGroup && store.model.NomenclatureGroup.INFO.WRITE,
		},
	],
};
