import React from 'react';
import { inject, observer } from 'mobx-react';
import { Text } from '@consta/uikit/Text';
import { Button } from '@consta/uikit/Button';
import { Modal } from '@consta/uikit/Modal';

import { t } from 'i18next';
import './BillingModalConfirms.scss';

@inject('context')
@observer
export class BillingModalConfirms extends React.Component {
	render() {
		const { closeConfirm, confirmStatus } = this.props.context;

		let propsConfirm = {
			onClose: closeConfirm,
		};

		switch (confirmStatus) {
			case 'failedPay':
				propsConfirm.title = t('billing.failedPayTitle');
				propsConfirm.description = t('billing.failedPayDescription');
				propsConfirm.buttonText = t('billing.payClose');
				propsConfirm.onConfirm = closeConfirm;
				break;
			case 'successPay':
				propsConfirm.title = t('billing.successPayTitle');
				propsConfirm.description = t('billing.successPayDescription');
				propsConfirm.buttonText = t('billing.payClose');
				propsConfirm.onConfirm = closeConfirm;
				break;
			default:
				return null;
		}

		return (
			<Modal
				isOpen={confirmStatus}
				onClickOutside={propsConfirm.onClose}
				onEsc={propsConfirm.onClose}
				className='BillingModalConfirms'
			>
				<div>
					<Text weight='bold' size='m'>
						{propsConfirm.title}
					</Text>
					<Text weight='light' view='secondary'>
						{propsConfirm.description}
					</Text>
				</div>
				<Button size='m' view='primary' label={propsConfirm.buttonText} width='default' onClick={propsConfirm.onConfirm} />
			</Modal>
		);
	}
}
