import { MESSAGES_SETTINGS_PATH } from '@pages/messages/constants';
import { Redirect } from 'react-router-dom';
import React from 'react';
import t from 'i18n';
import { Dictionary } from 'components';
import { DialogSources } from '@pages/messages/settings/dialog-sources';
import { PageNotFound } from '@widgets/PageNotFound';

export default (appStore) => [
	{
		path: MESSAGES_SETTINGS_PATH,
		children: <Redirect to={`${MESSAGES_SETTINGS_PATH}/statuses`} />,
		exact: true,
	},
	{
		path: `${MESSAGES_SETTINGS_PATH}/statuses`,
		label: t('dialogStatus.plural'),
		render: (props) => <Dictionary {...props} modelName='DialogStatus' />,
		disabled: !appStore.model.DialogStatus || !appStore.model.DialogStatus.INFO.WRITE,
	},
	{
		path: `${MESSAGES_SETTINGS_PATH}/dialog-sources`,
		label: t('dialogSource.plural'),
		render: (props) => <DialogSources {...props} />,
		disabled: !appStore.model.DialogSource || !appStore.model.DialogSource.INFO.WRITE,
	},
	{
		path: '*',
		component: PageNotFound,
	},
];
