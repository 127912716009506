import React from 'react';
import { formatDate } from 'client/tools';
import t from 'i18n';
import './dialog-card.scss';
import ChatIcon from '../../../../../../img/icons/cloud-chat.svg';

export default class DialogCard extends React.Component {
	constructor(props) {
		super(props);
	}

	render() {
		const { dialog } = this.props;

		const relationRecords = {};
		if (parseInt(dialog.countTask)) relationRecords.task = dialog.countTask;
		if (parseInt(dialog.countOrder)) relationRecords.order = dialog.countOrder;
		if (parseInt(dialog.countDocument)) relationRecords.document = dialog.countDocument;

		return (
			<div className='wrapper-kanban-card'>
				<div className='row-kanban-card top'>
					<p>#{dialog.id}</p>
					<p>{formatDate(dialog.updatedAt)}</p>
				</div>
				<div className='row-kanban-card head'>
					<p>{dialog.name || dialog.email || dialog.phone || dialog.username}</p>
				</div>
				{relationRecords && (
					<div className='row-kanban-card relation-records'>
						{Object.keys(relationRecords).map((key) => (
							<span className={key} key={key}>
								{t(`${key}.plural`)} ({relationRecords[key]})
							</span>
						))}
					</div>
				)}
				<div className='row-kanban-card'>
					<ChatIcon />
				</div>
			</div>
		);
	}
}
