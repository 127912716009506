import Root from './Root';
import React from 'react';
import {
	MANUFACTURE_PRODUCTION_WORKSHOPS_PATH,
	MANUFACTURE_EQUIPMENTS_PATH,
	MANUFACTURE_PRODUCTION_PROCESSES_PATH,
	MANUFACTURE_MATERIALS_PATH,
	MANUFACTURE_TECH_MAP_PATH,
	MANUFACTURE_SETTINGS_PATH,
	MANUFACTURE_PRODUCTION_ORDERS_PATH,
	MANUFACTURE_REPORTS_PATH,
	MANUFACTURE_DASHBOARD_PATH,
} from '@pages/constants';
import t from 'i18n';

import DashboardIcon from '@phosphor-icons/core/regular/chart-pie-slice.svg';
import ProductionsIcon from '@phosphor-icons/core/regular/stack.svg';
import WorkshopsIcon from '@phosphor-icons/core/regular/factory.svg';
import EquipmentsIcon from '@phosphor-icons/core/regular/wrench.svg';
import ProcessesIcon from '@phosphor-icons/core/regular/gear.svg';
import MaterialsIcon from '@phosphor-icons/core/regular/gas-can.svg';
import TechMapsIcon from '@phosphor-icons/core/regular/git-branch.svg';
import ReportsIcon from '@phosphor-icons/core/regular/cards-three.svg';
import SettingsIcon from '@phosphor-icons/core/regular/sliders-horizontal.svg';

export default {
	title: t('productionOrder.menu'),
	titleShort: t('productionOrder.titleShort'),
	code: 'manufacture',
	enabled: (store) => store.model.ProductionOrder && store.model.ProductionOrder.INFO.READ,
	component: Root,
	menu: () => [
		{
			type: 'item',
			label: t('analytics.title'),
			path: MANUFACTURE_DASHBOARD_PATH,
			icon: () => <DashboardIcon size='m' />,
		},
		{
			type: 'item',
			label: t('productionOrder.plural'),
			path: MANUFACTURE_PRODUCTION_ORDERS_PATH,
			icon: () => <ProductionsIcon size='m' />,
			enabled: ({ model }) => model.ProductionOrder?.INFO.READ,
		},
		{
			type: 'item',
			label: t('productionOrder.workshops'),
			path: MANUFACTURE_PRODUCTION_WORKSHOPS_PATH,
			icon: () => <WorkshopsIcon size='m' />,
			enabled: ({ model }) => model.ProductionWorkshop?.INFO.READ,
		},
		{
			type: 'item',
			label: t('productionOrder.equipments'),
			path: MANUFACTURE_EQUIPMENTS_PATH,
			icon: () => <EquipmentsIcon size='m' />,
			enabled: ({ model }) => model.ProcessEquipment?.INFO.READ,
		},
		{
			type: 'item',
			path: MANUFACTURE_PRODUCTION_PROCESSES_PATH,
			label: t('productionOrder.process'),
			icon: () => <ProcessesIcon size='m' />,
			enabled: ({ model }) => model.ProductionProcess?.INFO.READ,
		},
		{
			type: 'item',
			path: `${MANUFACTURE_MATERIALS_PATH}`,
			label: t('productionOrder.materials'),
			icon: () => <MaterialsIcon size='m' />,
			enabled: ({ model }) => model.Material?.INFO.READ,
		},
		{
			type: 'item',
			path: MANUFACTURE_TECH_MAP_PATH,
			label: t('productionOrder.techMap'),
			icon: () => <TechMapsIcon size='m' />,
			enabled: ({ model }) => model.ProductionWorkshop?.INFO.READ,
		},
		{
			type: 'item',
			label: t('reports.plural'),
			path: MANUFACTURE_REPORTS_PATH,
			icon: () => <ReportsIcon size='m' />,
		},
		{
			type: 'item',
			path: MANUFACTURE_SETTINGS_PATH,
			label: t('settings'),
			icon: () => <SettingsIcon size='m' />,
			enabled: ({ model }) => model.ProductionOrder && model.ProductionOrder.INFO.WRITE && model.ProductionOrderStatus && model.ProductionOrderStatus.INFO.WRITE,
		},
	],
};
