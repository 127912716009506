import React from 'react';
import store from 'client/store';
import classNames from 'classnames';
import { NumberInput } from '@smartplatform/consta/ui/NumberInput';

export const CurrencyInput = (props) => {
	const { currency, placeholder, className, ...restProps } = props;
	const locale = store.model.user.language?.code || 'ru';

	// название валюты из локали
	const currencySymbol = currency
		? (0)
				.toLocaleString(locale, {
					style: 'currency',
					currency: currency,
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				})
				.replace(/\d/g, '')
				.trim() + ' '
		: null;

	// десятичный сепаратор из локали
	const { decimalSeparator, delimiter } = (() => {
		// defaults
		let res = { decimalSeparator: '.', delimiter: '' };

		// convert a number formatted according to locale
		const str = (1234.56).toLocaleString(locale);

		// if the resulting number does not contain previous number
		// (i.e. in some Arabic formats), return defaults
		if (!str.match('1')) return res;

		// get decimal and thousand separators
		res.decimalSeparator = str.replace(/.*4(.*)5.*/, '$1');
		res.delimiter = str.replace(/.*1(.*)2.*/, '$1');

		// return results
		return res;
	})();

	const _className = classNames('CurrencyInput', className);
	const _placeholder = placeholder || '0';

	return (
		<NumberInput
			{...restProps}
			leftSide={currencySymbol}
			className={_className}
			placeholder={_placeholder}
			positiveOnly={true}
			decimalScale={2}
			decimalMark={decimalSeparator}
			delimiter={delimiter}
			stripLeadingZeroes={true}
			integerScale={15}
		/>
	);
};
