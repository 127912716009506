import React from 'react';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { Button } from '@consta/uikit/Button';
import { NotPhone } from 'components';
import CoinsIcon from '@phosphor-icons/core/regular/coins.svg';

import store from 'client/store';
import { t } from 'i18next';
import './BillingButton.scss';

@observer
export class BillingButton extends React.Component {
	@observable isBilling = false;

	constructor(props) {
		super(props);
		this.init();
	}

	init = async () => {
		// заменить на isBilling из конфига, когда появится возможность получить isBilling
		const isBillingResponse = await fetch('/api/isBilling', {
			method: 'POST',
			mode: 'cors',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
			},
		});
		if (isBillingResponse.ok) {
			this.isBilling = await isBillingResponse.json();
		}
	};

	onTogglePopup = () => {
		store.ui.showPopupBilling = !store.ui.showPopupBilling;
	};

	render() {
		if (!this.isBilling) return null;
		return (
			<>
				<NotPhone>
					<Button
						label={t('billing.tariff')}
						ref={this.props.inputRef}
						size='s'
						onClick={this.onTogglePopup}
						className='BillingButton'
						iconLeft={CoinsIcon}
					/>
				</NotPhone>
			</>
		);
	}
}
