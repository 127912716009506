export const processText = (html) => {
	if (html) {
		// нельзя использовать lookbehind - (?<= ) и (?<! ) - https://caniuse.com/js-regexp-lookbehind
		// временно заменяем готовые гиперссылки (тэги <a>) на "--anchor[N]--" и сохраняем их в массиве
		const anchorMatch = html.match(/<a.*<\/a>/gi);
		const anchors = [];
		if (anchorMatch) {
			anchorMatch.forEach((anchor, i) => {
				anchors.push(anchor);
				html = html.replace(anchor, `--anchor${i}--`);
			});
		}
		// заменяем голые URL на гиперссылки
		const urlMatch = html.match(/((?:[^"'])(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi);
		if (urlMatch) {
			const urlMatchWithoutDouble = [...new Set(urlMatch.map((item) => item.slice(1)))];
			urlMatchWithoutDouble.forEach((url) => {
				html = html.replaceAll(url, `<a target="_blank" href="${url}">${url}</a>`);
			});
		}
		// возвращаем сохраненные ранее готовые гиперссылки
		anchors.forEach((anchor, i) => (html = html.replace(`--anchor${i}--`, anchor)));

		const imgMatch = html.match(/<img[^>]+>/gi);
		if (imgMatch) {
			imgMatch.forEach((imgTag, i) => {
				html = html.replace(imgTag, `<div class='image-editor'>${imgTag}</div>`);
			});
		}
	}
	return html;
};
