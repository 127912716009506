import { observable } from 'mobx';
import { MONTH, POST_OPTIONS } from './constants';

import store from 'client/store';

export class BillingStore {
	@observable info = {};
	@observable error = false;
	@observable isBilling = false;
	@observable modules = {};
	@observable changeTariffPayment = null;
	@observable isPaidTariff = false;
	@observable isTariffChange = false;
	@observable period = MONTH;
	@observable total = {};
	@observable discSpace = { start: 1, update: 0 };
	@observable isChangeSpace = false;
	@observable isLoading = true;
	@observable confirmStatus = null;
	anchorRef = null;

	constructor(props) {
		this.init();
		this.anchorRef = props.anchorRef;
	}

	init = async () => {
		this.isLoading = true;

		let fetchingResponse = await fetch('/api/getBilling', POST_OPTIONS);
		if (fetchingResponse.ok) {
			this.info = await fetchingResponse.json();
			this.period = this.info.period;
			this.changeTariffPayment = this.info.configTariffPayment;
			this.isPaidTariff = this.info.isPaidTariff;
			this.discSpace = this.info.discSpace;
			for (const module in this.info.modules) {
				this.modules[module] = { checked: this.info.modules[module].checked };
			}
			this.total = this.info.total;
		} else {
			this.error = fetchingResponse.status;
			return null;
		}

		if (!this.isPaidTariff) {
			// Ожидание оплаты, если находимся в режиме ожидания
			const time = setInterval(async () => {
				let isPaidResponse = await fetch('/api/isPaidTariff', {
					...POST_OPTIONS,
					body: JSON.stringify({ id: this.changeTariffPayment.paymentId }),
				});
				if (isPaidResponse.ok) {
					this.isPaidTariff = await isPaidResponse.json();
				} else {
					this.error = isPaidResponse.status;
					this.isLoading = false;
					return null;
				}

				if (this.isPaidTariff) {
					clearInterval(time);
					const _config = await store.model.Config.findOne({ where: { code: 'changeTariffPayment' } });
					_config.delete();
					store.ui.showPopupBilling = false;
					this.isPaidTariff = true;
					this.isChangeSpace = false;
					this.init();
				}
			}, 10000);
		}

		this.isLoading = false;
	};

	onTogglePopup = () => {
		if (!this.isLoading && !this.error) {
			store.ui.showPopupBilling = !store.ui.showPopupBilling;
		}
	};

	prolong = async () => {
		this.showPopup = false;

		let extendResponse = await fetch('/api/extendTariff', {
			...POST_OPTIONS,
			body: JSON.stringify({ elid: this.info.elid, period: this.period.value }),
		});
		if (extendResponse.ok) {
			this.confirmStatus = 'successPay';
			this.init();
			return null;
		}

		let orderResponse = await fetch('/api/orderExtension', {
			...POST_OPTIONS,
			body: JSON.stringify({ elid: this.info.elid, period: this.period.value }),
		});
		if (orderResponse.ok) {
			const { url } = await orderResponse.json();
			window.open(url, '_blank');
		} else {
			this.error = orderResponse.status;
			return null;
		}

		this.init();
	};

	isChangesModules = () => {
		return Object.entries(this.modules).some(([moduleName, module]) => {
			if (!(module.checked === this.info.modules[moduleName].checked)) {
				return true;
			}
		});
	};

	onChangeModule = (moduleName) => async () => {
		if (moduleName === 'management' && this.modules[moduleName].checked) return null;

		this.modules = {...this.modules, [moduleName]: {checked: !this.modules[moduleName].checked} };

		if (!this.info.isFree) {
			await this.getTotalEditTariff('edit');
		} else if (this.isChangeSpace && this.discSpace.update > 1 && this.info.isFree) {
			await this.getTotalEditTariff('transition');
		}

		this.isTariffChange = this.isChangesModules();
	};

	editTariff = async () => {
		this.confirmStatus = null;

		const response = await fetch('/api/editBilling', {
			...POST_OPTIONS,
			body: JSON.stringify({
				elid: this.info.elid,
				modules: this.modules,
				period: this.period,
				space: this.discSpace.update,
				isFree: this.info.tariffId === '19',
			}),
		});
		if (response.ok) {
			this.confirmStatus = 'successPay';
			return null;
		}

		let orderResponse = await fetch('/api/orderExtension', {
			...POST_OPTIONS,
			body: JSON.stringify({
				elid: this.info.elid,
				modules: this.modules,
				space: this.discSpace.update.toString(),
				isFree: this.info.tariffId === '19',
			}),
		});

		if (orderResponse.ok) {
			const { url } = await orderResponse.json();
			window.open(url, '_blank');
		} else {
			this.confirmStatus = 'failedPay';
			return null;
		}
		this.init();
		this.isChangeSpace = false;
		this.isTariffChange = false;
	};

	onChangePeriod = (value) => {
		this.period = value;
		this.getTotalEditTariff('transition');
	};

	onChangeSpace = async (value) => {
		const _value = Number(value);
		let discSpace;
		if (_value >= 1 && _value <= 100) {
			discSpace = { ...this.discSpace, update: _value };
		} else if (_value < 1) {
			discSpace = { ...this.discSpace, update: 1 };
		} else if (_value > 100) {
			discSpace = { ...this.discSpace, update: 100 };
		}

		this.discSpace = discSpace;
		const isChangeSpace = discSpace.start !== discSpace.update;
		if (!this.info.isFree) {
			await this.getTotalEditTariff('edit');
		} else if (isChangeSpace && _value > 1 && this.info.isFree) {
			await this.getTotalEditTariff('transition');
		} else if (!isChangeSpace) {
			await this.getTotalEditTariff('reset');
		}
		this.isChangeSpace = isChangeSpace;
	};

	getTotalEditTariff = async (action) => {
		if (action === 'edit') {
			let response = await fetch('/api/getTotalOfAdding', {
				...POST_OPTIONS,
				body: JSON.stringify({
					elid: this.info.elid,
					modules: this.modules,
					space: this.discSpace.update,
					tariffId: this.info.tariffId,
				}),
			});
			if (response.ok) {
				const { editTariff } = await response.json();
				this.total = {
					...this.total,
					editTariff,
				};
			}
		} else if (action === 'transition') {
			let response = await fetch('/api/getTotalOfAdding', {
				...POST_OPTIONS,
				body: JSON.stringify({
					modules: this.modules,
					space: this.discSpace.update,
					tariffId: this.info.tariffId,
					period: this.period.value,
				}),
			});
			if (response.ok) {
				const { editTariff } = await response.json();
				this.total = {
					...this.total,
					editTariff,
				};
			}
		} else if (action === 'reset') {
			this.total = {
				...this.total,
				editTariff: '0',
			};
		}
	};

	getChangeTariffAndPay = async () => {
		this.isPaidTariff = false;
		this.changeTariffPayment = true;

		let response = await fetch('/api/getChangeTariffAndPay', {
			...POST_OPTIONS,
			body: JSON.stringify({
				elid: this.info.elid,
				modules: this.modules,
				space: this.discSpace.update,
				isFree: this.info.isFree,
				period: this.period.value,
			}),
		});

		if (response.ok) {
			const { url, paymentId } = await response.json();
			await store.model.Config.batchUpdate([
				{
					code: 'changeTariffPayment',
					value: JSON.stringify({ paymentId, url }),
				},
			]);

			window.open(url, '_blank');
			this.init();
		}
	};

	onChangeTariff = () => {
		this.discSpace.update = this.isChangeSpace ? 1 : 2;
		this.getTotalEditTariff(this.isChangeSpace ? 'reset' : 'transition');
		this.isChangeSpace = !this.isChangeSpace;
	};

	cancelPayment = async () => {
		let response = await fetch('/api/isPaidTariff', {
			...POST_OPTIONS,
			body: JSON.stringify({ id: this.changeTariffPayment.paymentId }),
		});
		if (response.ok) {
			const isPaid = await response.json();
			if (isPaid) {
				this.init();
				return null;
			}
		}

		await fetch('/api/cancelPayment', {
			...POST_OPTIONS,
			body: JSON.stringify({ elid: this.info.elid }),
		});

		const changeTariffPayment = await store.model.Config.findOne({ where: { code: 'changeTariffPayment' } });
		await changeTariffPayment.delete();
		this.isPaidTariff = true;
		this.isChangeSpace = false;
		this.total = {
			...this.total,
			editTariff: '0',
		};

		this.init();
	};

	closePopup = () => (store.ui.showPopupBilling = false);

	closePopupOutside = (event) => {
		// для обработки повторного нажатия на открытие
		if (!this.anchorRef.current?.contains(event.target)) this.closePopup();
	};

	openConfirm = (prop) => () => {
		this.confirmStatus = prop;
	};

	closeConfirm = () => {
		this.confirmStatus = null;
	};
}
