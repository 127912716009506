import React from 'react';
import { inject, observer } from 'mobx-react';
import { Text } from '@consta/uikit/Text';
import { Badge } from '@consta/uikit/Badge';
import { CurrencyValue } from 'components';
import { relative } from 'client/tools';
import ChecksIcon from '@phosphor-icons/core/regular/checks.svg';
import ExclamationMarkIcon from '@phosphor-icons/core/regular/exclamation-mark.svg';

import { t } from 'i18next';
import './BillingHeader.scss';

@inject('context')
@observer
export class BillingHeader extends React.Component {
	render() {
		const { info, period } = this.props.context;

		const isActive = info.status === '2';
		const icon = isActive ? ChecksIcon : ExclamationMarkIcon;
		const label = isActive ? t('billing.active') : t('billing.stopped');
		const status = isActive ? 'success' : 'alert';

		return (
			<div className='BillingHeader'>
				<div className='billing-header-statusline'>
					<Text weight='bold' size='m'>
						{t('billing.tariff')} {info.tariffName}
					</Text>
					<Badge iconLeft={icon} label={label} status={status} view='filled' size='s' />
				</div>
				<div className='billing-line-wrapper'>
					<div className='billing-line'>
						<Text weight='light' view='secondary'>
							{t('billing.cost')}:{' '}
						</Text>
						<Text weight='bold'>
							<CurrencyValue value={info.totalBuyPeriod[period.title]} currency='RUB' /> / {t(period.title)}
						</Text>
					</div>
					<div className='billing-line'>
						<Text weight='light' view='secondary'>
							{t('billing.expireDate')}:{' '}
						</Text>

						<Text weight='bold'>{relative(info.expiredate)}</Text>
					</div>
				</div>
			</div>
		);
	}
}
