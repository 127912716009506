import store from '@appStore';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { Notifications } from 'components';
import loadable from '@loadable/component';
import { RequireAuth } from './hocs/RequireAuth';
import { AuthLayout, MainLayout } from './layouts';
import { RewritePassword } from 'components/rewrite-password';
import { observer } from 'mobx-react';
import { SwitchWith404 } from '@widgets/PageNotFound/SwitchWith404';
import { Loader } from '@consta/uikit/Loader';
import { useEffect } from 'react';

const AuthPage = loadable(() => import('@pages/auth'));
const Profile = loadable(() => import('components/profile'));
const Test = loadable(() => import('@pages/test'));

export const AppRouter = observer(() => {
	useEffect(() => {
		const location = new URL(window.location.href);
		store.local.lastPath = location.pathname;
		store.init();
	}, []);

	if (!store.isInitialized) return <Loader />;

	// пользователю нужно отдать только страницу с формой восстановления пароля
	const _passwordExpirationTime = parseInt(store.mergedConfig?.authentication?.password?.passwordExpirationTime);
	const enableRewrite = !!_passwordExpirationTime;

	if (enableRewrite && (!store.model.user.passwordTimeout || new Date() > new Date(store.model.user.passwordTimeout))) {
		return (
			<Router history={store.history} key={store.appKey}>
				<AuthLayout>
					<div className='auth'>
						<Route path='/' exact>
							<Redirect to='/rewrite-password' />
						</Route>
						<Route path='/rewrite-password' component={RewritePassword} />
						<Redirect from='/*' to='/rewrite-password' />
					</div>
				</AuthLayout>
			</Router>
		);
	}

	// отдаём пользователю осталное приложение
	const modulesRoutes = [];
	for (let module of store.availableModules) {
		const Module = module.component;
		modulesRoutes.push(<Route key={module.code} path={'/' + module.code} component={Module} />);
	}

	return (
		<Router history={store.history} key={store.appKey}>
			<Notifications />
			<Switch>
				{/* Auth **/}
				<Route path='/auth'>
					<AuthLayout>
						<Route path='/auth' component={AuthPage} />
					</AuthLayout>
				</Route>
				{/* Main app pages **/}

				<Route path='/'>
					<RequireAuth>
						<MainLayout>
							{modulesRoutes.length > 0 && (
								<SwitchWith404>
									{modulesRoutes}
									<Route path='/profile' component={Profile} />
									<Route path='/test' component={Test} />
									<Route path='/' exact>
										<Redirect to='/management/tasks' />
									</Route>
								</SwitchWith404>
							)}
						</MainLayout>
					</RequireAuth>
				</Route>
			</Switch>
		</Router>
	);
});
